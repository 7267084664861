@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-header: 255 255 255;
  --color-background: 255 255 255;
  --color-primary-text: 21 21 21;
  --color-primary-btn: linear-gradient(#57fe76, #1ebac4);
  --color-primary-bg: 19 19 28;
  --color-on-primary-bg: 30 30 41;
  --color-on-primary-bg-soft: 30 30 41;
  --color-on-primary-bg-softer: #656578;
  --color-on-hover-primary-text: #6e6e89;

  --color-secondary: #ffff;
  --color-secondary-bg: #132125;
  --color-on-secondary: #ffff;

  --color-accent: #24383e;
  --color-nano-green: 87 254 118;
  --color-error: 239 83 80;
}

html {
  height: 100vh;
  width: 100vw;
}
/* body {
  padding-top: 56px;
} */

.fixed {
  position: fixed;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  text-transform: capitalize;
}
input {
  border: none !important;
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-brand {
  color: #fff !important;
}

#img-src {
  display: none;
}

.btn-secondary {
  color: #000;
  background-color: #fff;
}

.tab-content {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.tool[type='image/svg+xml'] {
  /* padding-left: 10px;
  padding-right: 10px; */
  fill: #3f3b3b;
  cursor: pointer;
}

#grid-canvas {
  background-color: #f2f2f2;
}

body {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.tool-icon {
  display: block;
  margin: auto;
  height: 25px !important;
  width: 25px !important;
}

/* .tool-icon path {
  fill: #000;
} */

.grid-icon {
  display: block;
  margin: auto;
  height: 25px !important;
  width: 25px !important;
}

.grid-icon.on rect {
  fill: #4bb543;
  background-color: #4bb543;
}

.grid-icon.off rect {
  fill: #000;
  background-color: #000;
}

.paging {
  border: none;
  position: fixed;
  margin: 0;
  z-index: 100;
}

.tab-pane > button {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.8em;
  height: 60px;
  width: 110px;
}

.tab-content > div {
  text-align: center;
}

.paging-up {
  top: 61;
  left: 50%;
}
.paging-up-right {
  top: 61;
  right: 0;
}
.paging-right {
  top: 50%;
  right: 10;
}
.paging-down-right {
  bottom: 5;
  right: 10;
}
.paging-down {
  bottom: 5;
  left: 50%;
}
.paging-down-left {
  bottom: 10;
  left: 0%;
}
.paging-left {
  top: 50%;
  left: 10;
}
.paging-up-left {
  top: 61;
  left: 10;
}

.hidden {
  display: none;
}

#sidebar {
  width: 250px;
  position: fixed;
  top: 56;
  right: 0;
  height: calc(100% - 56px);
  z-index: 999;
  color: #fff;
  margin-right: -250px;
  transition: all 0.5s;
  overflow: auto;
}

#sidebar.active {
  margin-right: 0px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#content {
  /* width: calc(100% - 250px); */
  height: calc(100% - 56px);
  transition: all 0.5s;
  position: absolute;
  top: 56;
  right: 0;
  transition: all 0.5s;
}

#content.active {
  width: 100%;
}

.navbar-toggler {
  padding: 5px;
}

#hidden-input {
  border: inherit;
  color: black !important;
  visibility: visible !important;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#disable-touch-action {
  touch-action: none;
}

.toggle-bg:after {
  content: '';
  position: absolute;
  top: 0.125rem /* 2px */;
  left: 0.125rem /* 2px */;
  background: white;
  border-color: #d1d5db;
  border-width: 1px;
  border-radius: 9999px;
  height: 1.25rem /* 20px */;
  width: 1.25rem /* 20px */;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: 0.15s;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
  border-color: white;
}
input:checked + .toggle-bg {
  background: rgb(218, 91, 37, 0.25) !important;
  border-color: rgb(218, 91, 37, 0.25) !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 0.5rem;
  border: 1px solid #ed5521;
  color: #ed5521;
  font-family: 'Montserrat' !important;
}

.pagination__link {
  font-weight: bold;
  font-family: 'Montserrat' !important;
  padding: 10px;
}

.pagination__link--active a {
  color: #fff;
  padding: 10px;
  background: #ed5521;
}

.pagination__link--disabled a {
  color: #cbd5e1;
  border: 1px solid rgb(198, 197, 202);
}

.magnifier {
  /* Styles for <div> around image and magnifying glass */
}

.magnifier-image {
  /* Styles for large image */
  aspect-ratio: 1 / 1 !important;
  border-radius: 50%;
}

.magnifying-glass {
  /* Styles for magnifying glass */
  z-index: 999 !important;
}

/* .MuiBadge-badge {
  background-color: rgb(218, 91, 37);
  color: white;
  padding: 0 !important;
} */
